import { useEffect, useMemo, useRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import i18next from "i18next";
import { canActivateLink } from "../../utils";
import { IMultiOptionsMenuItemProps } from "../../interfaces/menuOptions";

export const MultiOptionsMenuItem = ({
  context,
  feature,
  flags,
  icon,
  iconKind,
  lastOpenedModule,
  moduleName,
  options,
  permissions,
  setLastOpenedModule,
  title,
  token,
  language,
  toggleMenuInMobile,
  isSidebarFolded,
  hoveredItem,
  setHoveredItem,
  isModule,
}: IMultiOptionsMenuItemProps) => {
  const location = useLocation();
  const [isUserInModule, setIsUserInModule] = useState(false);
  const [shouldPlaceMenuOnTop, setShouldPlaceMenuOnTop] = useState(false);

  const modulesOptionsRef = useRef<HTMLInputElement | null>(null);
  const isHovered = useMemo(() => hoveredItem === moduleName, [hoveredItem]);

  //We need to check what subModule in the url is active (units, breakdowns, graphics, etc.) to keep the submenus visible
  let subModulePath = `${location.pathname.split("/")[2]}`;

  useEffect(() => {
    subModulePath =
      subModulePath === "unit" || subModulePath === "devices"
        ? "units"
        : subModulePath;
    setIsUserInModule(
      options.filter((opt) => {
        const url =
          typeof opt.url === "function" ? opt.url(token, language) : opt.url;
        return (
          location.pathname.split("/")[1] === url.split("/")[0] &&
          url.split("/")[1] === subModulePath
        );
      }).length !== 0
    );
  }, [location]);

  useEffect(() => {
    if (!modulesOptionsRef.current) return;

    setShouldPlaceMenuOnTop(
      modulesOptionsRef.current.getBoundingClientRect().height +
        modulesOptionsRef.current.getBoundingClientRect().y >
        window.innerHeight
    );
  }, [modulesOptionsRef, isHovered]);

  const toggleDropdown = () => {
    if (options.filter((opt) => opt.url === subModulePath).length === 0) {
      setLastOpenedModule(moduleName === lastOpenedModule ? "" : moduleName);
    }
  };

  return (
    <div
      role="button"
      tabIndex={0}
      id={`vegga-sidebar__${context}`}
      className={`vegga-sidebar__modules-dropdown ${
        isSidebarFolded && isModule
          ? "vegga-sidebar__modules-dropdown--folded"
          : ""
      }`}
      onClick={toggleDropdown}
      onMouseEnter={() => setHoveredItem(moduleName)}
      onMouseLeave={() => setHoveredItem("")}
    >
      <div className="vegga-sidebar__modules-item">
        <span className="vegga-sidebar__modules-icon">
          <vegga-icon kind={iconKind || "deprecated"} icon={icon}></vegga-icon>
        </span>
        {!isSidebarFolded && (
          <span className="vegga-sidebar__modules-label">
            {i18next.t(title)}
          </span>
        )}
      </div>

      <div
        ref={modulesOptionsRef}
        style={shouldPlaceMenuOnTop ? { bottom: 0 } : { top: 0 }}
        className={
          isSidebarFolded
            ? "vegga-sidebar__modules-options vegga-sidebar__modules-options--folded"
            : "vegga-sidebar__modules-options"
        }
      >
        {((isUserInModule || moduleName === lastOpenedModule) &&
          !isSidebarFolded) ||
        (isHovered && isSidebarFolded) ? (
          <>
            {isSidebarFolded && (
              <div
                className="vegga-sidebar__modules-item vegga-sidebar__modules-item--folded"
                style={isModule ? { bottom: 0 } : {}}
              >
                <span
                  className="vegga-sidebar__modules-label"
                  style={{ paddingLeft: "12px" }}
                >
                  {i18next.t(title)}
                </span>
              </div>
            )}
            {options.map((option, index) => {
              const { feature, flags, permissions } = option;

              const isVisible = canActivateLink(feature, flags, permissions);

              if (!isVisible) return;
              const shouldBeActive =
                typeof option.url === "string" &&
                option.url.includes("/unit") &&
                location.pathname.includes("/unit");
              return option.external ? (
                <a
                  key={index}
                  href={
                    typeof option.url === "function"
                      ? option.url(token, language)
                      : option.url
                  }
                  className="vegga-sidebar__modules-subitem"
                  onClick={toggleMenuInMobile}
                >
                  <span>{i18next.t(option.option)}</span>
                </a>
              ) : (
                <NavLink
                  key={index}
                  to={`${option.url}`}
                  className={({ isActive }) => {
                    return isActive || shouldBeActive
                      ? "vegga-sidebar__modules-subitem vegga-sidebar__modules-subitem--active"
                      : "vegga-sidebar__modules-subitem";
                  }}
                  onClick={toggleMenuInMobile}
                >
                  <span>{i18next.t(option.option)}</span>
                </NavLink>
              );
            })}
          </>
        ) : null}
      </div>
    </div>
  );
};
